import React from "react";
import Header from "./header";
import Navbar from "./navbar";
import Footer from "./footer";
import "../css/regulativa.css";
import pdfIcon from "../../src/PDF_24.png";
import wordIcon from "../../src/word_icon.jfif";
import Fade from "react-reveal/Fade";
import registar from "../../src/Registar na brokeri 2024.docx";

function Regulativa() {
  return (
    <div>
      <Header />
      <Navbar />
      <div className="regulativa-container">
        <h1 className="regulativa-title">
          <Fade left cascade>
            РЕГУЛАТИВА
          </Fade>
        </h1>
        <Fade>
          <div className="regulativa-text">
            Агенцијата за супервизија на осигурување е основано како независно
            регулативно тело со надлежности и овластувања да се грижи за
            законско и ефикасно функционирање на пазарот на осигурување, со цел
            заштита на правата на осигурениците и корисниците на осигурителните
            услуги.
          </div>
        </Fade>
        <div className="regulativa-zakon">
          <p>
            <a
              href="https://aso.mk/wp-content/uploads/2020/02/zso-neoficijalen-precisten-31-2020.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={pdfIcon}></img>
              Неофицијален пречистен текст на закон за супервизија на
              осигурување (pdf)
            </a>{" "}
            <br />
            <a href={registar} download rel="noopener noreferrer">
              <img src={wordIcon}></img>
              Регистар на брокери 2024 (docx)
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Regulativa;
