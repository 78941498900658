import React from "react";
import "../css/header.css";
import Flip from "react-reveal/Flip";

import logo from "../images/LogoDeltaINSLogo.svg";
import face_icon from "../images/face_icon.svg";
import twit_icon from "../images/twit_icon.svg";
import insta_icon from "../images/insta_icon.svg";

function Header() {
  return (
    <div className="header">
      <h3 className="number">
        {" "}
        &#9990; <a href="tel:076422200"> 076 422 200</a>
      </h3>
      <Flip>
        <img className="logo" src={logo} alt="logo"></img>
      </Flip>
      <h3 className="title"> ОСИГУРИТЕЛНO БРОКЕРСКО ДРУШТВО</h3>

      <div className="social_icons">
        <a href="#">
          <img
            className="face_ico icon"
            src={face_icon}
            alt="logo_face_icon"
          ></img>
        </a>
        <a href="#">
          <img
            className="twit_ico icon"
            src={twit_icon}
            alt="logo_twit_icon"
          ></img>
        </a>
        <a href="#">
          <img
            className="insta_ico icon"
            src={insta_icon}
            alt="logo_insta_icon"
          ></img>
        </a>
      </div>
    </div>
  );
}

export default Header;
