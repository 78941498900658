import React from "react";
import { useState } from "react";
import { BrowserRouter as Router, Switch, NavLink } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Footer from "./footer";
import Header from "./header";
import Navbar from "./navbar";
import "../css/kontakt.css";
import logo from "../images/LogoDeltaINSLogo.svg";

function Kontakt() {
  const [user, setUser] = useState({
    ime: "",
    prezime: "",
    email: "",
    tel: "",
  });

  return (
    <div>
      <Header />
      <Navbar />
      <div className="kontakt-container">
        <Fade bottom cascade>
          <div className="kontakt-left">
            <div className="kon-tel">
              <h3>КОНТАКТ ИНФОРМАЦИИ</h3>

              <h3>076 422 200</h3>
            </div>
            <div className="agenti">
              <div className="agent ivan">
                <h4>Иван Клинчаров</h4>

                <a href="mailto:ivan@deltains.mk" className="agent-mail">
                  ivan@deltains.mk
                </a>
              </div>
              <div className="agent biljana">
                <h4>Билјана Иванова</h4>

                <a
                  href="mailto:biljanadeltains@gmail.com"
                  className="agent-mail"
                >
                  biljanadeltains@gmail.com
                </a>
              </div>
            </div>
            <div className="logo-info">
              <NavLink exact to="/">
                <img src={logo}></img>
              </NavLink>
              <br />
              <a href="mailto:ivan@deltains.mk" className="agent-mail">
                ivan@deltains.mk
              </a>
            </div>
          </div>
        </Fade>
        {/* <div className="kontakt-right">
          <Fade>
            <div className="poraka-form-container">
              <h4>ОСТАВЕТЕ ПОРАКА</h4>
              <hr></hr>
              <form method="GET">
                <input
                  type="text"
                  name="ime"
                  size="20"
                  value={user.ime}
                  onChange={(e) => setUser({ ...user, ime: e.target.value })}
                  placeholder="Име"
                  required
                ></input>
                <input
                  type="text"
                  name="prezime"
                  size="20"
                  placeholder="Презиме"
                  required
                ></input>
                <input
                  type="email"
                  name="email"
                  size="20"
                  placeholder="Email"
                  required
                ></input>
                <input
                  type="tel"
                  name="tel"
                  size="10"
                  placeholder="Телефон"
                  required
                ></input>
                <textarea
                  id="poraka"
                  name="poraka"
                  rows="16"
                  required
                ></textarea>
                <input type="submit" value="ИСПРАТИ"></input>
              </form>
            </div>
          </Fade>
          <div className="map"></div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default Kontakt;
